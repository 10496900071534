<!--
 * @Descripttion:
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-23 16:18:02
-->
<template>
    <el-main>
      <h1 class="base-title">绑定采购人</h1>
      <div class="search mt">
        <el-input placeholder="请输入随机码" v-model="searchVal" class="input-with-select">
          <el-button slot="append" icon="el-icon-plus" @click="onBindBtnStore">绑定</el-button>
        </el-input>
      </div>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="#"  width="100">
          <template #default="scope">
            <span >{{ scope.row.order }}</span>
          </template>
        </el-table-column>
        <el-table-column label="采购人名称" min-width="300">
          <template #default="scope">
            <span >{{ scope.row.storeName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="联系人"   min-width="150">
          <template #default="scope">
            <span >{{ scope.row.linkMan }}</span>
          </template>
        </el-table-column>
        <el-table-column label="联系电话"   min-width="150">
          <template #default="scope">
            <span >{{ scope.row.linkPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="联系地址" >
          <template #default="scope">
            <span >{{ scope.row.linkAddr }}</span>
          </template>
        </el-table-column>
		<el-table-column label="审核状态" >
			<template #default="scope">
				<span v-if="scope.row.supplierAuditStatus=='00'">待审核</span>
				<span v-else-if="scope.row.supplierAuditStatus=='01'">通过</span>
				<span v-else-if="scope.row.supplierAuditStatus=='02'">不通过</span>
				<span v-else-if="scope.row.supplierAuditStatus=='03'">临时通过</span>
				<span v-else>不通过</span>
			</template>
		</el-table-column>
		<el-table-column label="审核意见" >
			<template #default="scope">
				<span >{{scope.row.supplierAuditOpinion}}</span>
			</template>
		</el-table-column>
		<el-table-column label="操作" >
			<template #default="scope">
				<el-button size="mini" type="primary" v-if="scope.row.supplierAuditStatus=='02'" @click="submitAudit(scope.row)">提交审核</el-button>
			</template>
		</el-table-column>
        <!-- <el-table-column label="操作员"  width="100">
          <template #default="scope">
            <span >{{ scope.row.operator }}</span>
          </template>
        </el-table-column> -->


        <!-- <el-table-column label="创建时间"  width="150">
          <template #default="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.date }}</span>
          </template>
        </el-table-column> -->

        <!-- <el-table-column label="操作">
          <template #default="scope">
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)">查看</el-button>

               <el-button
              size="mini" type="primary"
              @click="handleEdit(scope.$index, scope.row)">审核</el-button>
              <el-button  size="mini" type="danger">停用</el-button>
              <el-button  size="mini" type="success">设置分类</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="block page-pagination">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next"
          :total="10">
        </el-pagination>
      </div>
    </el-main>
</template>

<script>
import {httpPost,httpGet,httpPut} from '@/utils/http'
import global from '@/common/global';

export default {
	name: 'Bind',
	data(){
		return {
			searchVal: '',
			inputStyle:{width:'300px'},
			tableData: [],
			urls:{
				onBindBtnStore: '/ewbid/map/tbEwBnSuppMap/onBindBtnStore',
			}
		}
	},
	created() {
		this.getBindedAgencyList();
	},
	methods: {
		//提交审核
		submitAudit:function(row){
			let url = global.gateway+"/ewbid/map/tbEwBnSuppMap/submitAudit"
			httpGet(url,{
				mapId:row.mapId
			}).then(res=>{
				if(res.status=="200"&&res.data.retCode=="0"){
					//检索
					this.getBindedAgencyList()
					//提示
					this.$message({
						type: 'success',
						message: '提交成功!'
					})
				}else{
					this.$message({
						type: 'info',
						message: '提交失败'
					})
				}
			}).catch(err=>{
				console.log(err)
			})
		},
		//
		handleEdit(index, row) {
			console.log(index, row);
		},
		handleDelete(index, row) {
			this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$message({
					type: 'success',
					message: '删除成功!'
				});
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		//绑定采购单位
		onBindBtnStore(){
			if(this.searchVal.trim()==''){
				this.$message({
					type: 'error',
					message: '6位随机码不能为空'
				});
				return;
			}
			//参数
			let objData={};
			objData.sixRandomCode = this.searchVal;//六位随机码
			objData.supplierStoreId = this.$ls.get("storeInfo").id
			//进行绑定
			let url = global.gateway+"/ewbid/map/tbEwBnSuppMap/onBindBtnStore"
			httpPost(url,objData).then(res=>{
				if(res.status=="200"&&res.data.success){
					this.getBindedAgencyList()
					this.$message({
						type: 'success',
						message: '绑定成功！'
					})
				}else{
					this.$message({
						type: 'error',
						message: '绑定失败！'
					})
				}
			}).catch(err=>{
				alert(JSON.stringify(err))
			})
		},
		//检索当前供应商绑定的采购单位列表
		getBindedAgencyList:function(){
			//供应商主键
			let supplyId = this.$ls.get("storeInfo").id
			//查询已绑定代理机构
			let that = this
			this.tableData = []
			let url = global.gateway+"/ewbid/map/tbEwBnSuppMap/getBindedAgencyWithSupply"
			httpGet(url,{
				'storeId':supplyId
			}).then(res=>{
				if(res.status=="200"&&res.data.retCode=="0"){
					if(res.data.ewStores&&res.data.ewStores.length>0)
						that.tableData = res.data.ewStores
				}else{
					this.$message({
						type: 'error',
						message: '数据查询失败！'
					})
				}
			}).catch(err=>{
				this.$message({
					type: 'error',
					message: '数据查询失败！'
				})
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.el-container {
  background-color: #fff;
  height: 100%;
  .el-header {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    align-items: center;
    h1 {
      font-size: 23px;
      font-weight: bold;
      margin-right: 30px;
    }
  }
  .el-main {
    // position: relative;
    // width: 100%;
    // overflow: hidden;
    .search {
      display: flex;
      justify-content: flex-start;
      .el-input {
        width: 300px;
      }
    }
    .page-pagination {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
  }
}

</style>